.form-bg {
  background-image: url(../../../public/assets/formBg.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgb(255, 54, 61);
    background: linear-gradient(
      180deg,
      rgba(255, 54, 61, 1) 0%,
      rgba(236, 28, 36, 1) 25%,
      rgba(158, 0, 6, 1) 59%,
      rgba(113, 11, 15, 1) 100%
    );
    opacity: 0.9;
  }
}
