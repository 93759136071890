//
// _helper.scss
//

// Define the font sizes
$font-size-mixing: 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 26, 28, 30, 32, 34, 36, 44, 48, 54, 60, 68, 74;

// Define Bootstrap breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Mixin to generate responsive font sizes
@mixin font-size-breakpoints($font-size) {
  @each $breakpoint, $value in $breakpoints {
    @if $breakpoint == xs {
      .fs-#{$breakpoint}-#{$font-size} {
        font-size: #{$font-size}px !important;
      }
    } @else {
      @media (max-width: $value) {
        .fs-#{$breakpoint}-#{$font-size} {
          font-size: #{$font-size}px !important;
        }
      }
    }
  }
}

// Apply the mixin to each font size
@each $font-size in $font-size-mixing {
  @include font-size-breakpoints($font-size);
}

// Additional styles for default font sizes
@each $font-size in $font-size-mixing {
  .fs-#{$font-size} {
    font-size: #{$font-size}px !important;
  }
}

// Apply mixin for each font size and breakpoint
@each $font-size in $font-size-mixing {
  @include font-size-breakpoints($font-size);
}
// border-color
.border-dark {
  border-color: var(--#{$prefix}dark) !important;
}
.border-light {
  border-color: var(--#{$prefix}light) !important;
}

// Border Style
$border-style-mixing: double, dashed, groove, outset, ridge, dotted, inset;
@each $border-style-mixing in $border-style-mixing {
  .border-#{$border-style-mixing} {
    border-style: #{$border-style-mixing} !important;
    border-color: #{$border-color-translucent};
  }
  .border-top-#{$border-style-mixing} {
    border-top-style: #{$border-style-mixing} !important;
    border-color: #{$border-color-translucent};
  }
  .border-bottom-#{$border-style-mixing} {
    border-bottom-style: #{$border-style-mixing} !important;
    border-color: #{$border-color-translucent};
  }
  .border-end-#{$border-style-mixing} {
    border-right-style: #{$border-style-mixing} !important;
    border-color: #{$border-color-translucent};
  }
  .border-start-#{$border-style-mixing} {
    border-left-style: #{$border-style-mixing} !important;
    border-color: #{$border-color-translucent};
  }

  // List Group Flush Style
  .list-group-flush {
    &.border-#{$border-style-mixing} {
      border: none !important;
      .list-group-item {
        border-style: #{$border-style-mixing} !important;
      }
    }
  }
}
// Icons Sizes
.icon-xs {
  height: 16px;
  width: 16px;
}
.icon-sm {
  height: 18px;
  width: 18px;
}
.icon-md {
  height: 22px;
  width: 22px;
}
.icon-lg {
  height: 24px;
  width: 24px;
}
.icon-xl {
  height: 28px;
  width: 28px;
}
.icon-xxl {
  height: 32px;
  width: 32px;
}
