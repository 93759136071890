@import "../scss/config/material/bootstrap.scss";
@import "../scss/config/material/app.scss";

body {
  * {
    outline: none;
  }
}
.aspect-ratio {
  aspect-ratio: 1 / 1; /* Sets the aspect ratio to 1:1 */
}
.sticky-scroll {
  position: sticky;
  z-index: 2;
  top: 65px;
  display: block;
}
