.circle {
  width: 26px;
  height: 26px;
}
.is-invalid .css-13cymwt-control {
  border-color: red;
}
.is-invalid .css-1xc3v61-indicatorContainer {
  color: red;
}
.is-invalid .css-1u9des2-indicatorSeparator {
  background-color: red;
}
.custom-hover {
  &:hover {
    color: var(--dh-btn-active-color) !important;
    background-color: var(--dh-btn-active-bg) !important;
    border-color: var(--dh-btn-active-border-color) !important;
  }
}